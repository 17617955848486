.quality-container{
    padding: 100px 20% 0px 20% !important;
}

.quality-header{
    font-size: 70px !important;
    font-weight: bolder !important;
    color: #000000;
}

.quality-description{
    padding: 50px !important;
    font-family: 'Substance' !important;
    font-weight: 300 !important;
    font-size: 28px !important;
    /* font: normal normal 100 28px/36px 'Substance-thin' !important; */
    letter-spacing: 0px !important;
    color: #000000 !important;
    opacity: 1 !important;
    text-align: left !important;
}

.quality-left-container{
    padding: 40px 70px 30px 5% !important;
    position: relative !important;
}
.quality-right-container{
    border-left: 5px #000 dashed !important;
    border-image: url("../../assets/dottedLine.png") 400 stretch;
    padding: 40px 5% 30px 5% !important;
}

.quality-image{
    width: 194px;
    height: 119px;
    position: absolute;
    right: 70px;
    top: 50%;
    transform: translate(0,-50%);
}

.quality-image-icon{
    width: 150px;
    height: 162px;
}
.quality-desc{
    width: 478px;
    height: 96px;
    font-size: 36px !important;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: bold !important;
}

.quality-date{
    font-weight: bold !important;
    font-size: 16px !important;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 20px !important;   
}

.quality-month-year{
    font-weight: bold !important;
    font-size: 52px !important;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 10px !important;
}

.farm-certificate-container{
    position: relative !important;
}
.farm-image{
    width: 100% !important;
}
.certificate-image{
    position: absolute !important;
    right: 50% !important;
    bottom: 70px !important;
}

@media only screen and (max-width: 767px) {
    .quality-container{
        padding: 30px 5% 0px 5% !important;
    }
    
    .quality-header{
        font-size: 30px !important;
    }
    
    .quality-description{
        padding: 30px !important;
        font-size: 13px !important;
    }


    .quality-left-container{
        padding: 30px 70px 30px 5% !important;
    }
    .quality-right-container{
        border-left: 2px #000 dashed !important;
        border-image: url("../../assets/dottedLine.png") 350 stretch;
        padding: 30px 5% 30px 5% !important;
    }
    .quality-image{
        width: 88px;
        height: 54px;
    }
    .quality-image-icon{
        width: 55px;
        height: 57px;
    }
    .quality-desc{
        width: 128px;
        height: 50px;
        font-size: 13px !important;   
    }
    .quality-date{
        font-size: 10px !important;
    }
    .quality-month-year{
        font-size: 13px !important;
    }
    .certificate-image{
        bottom: 30px !important;
        height: 123px !important;
        width: 87px !important;
    }
}