body{
  font-family: 'Kenac' !important;
}
div{
  font-family: 'Kenac' !important;
}
.text-align-center{
  text-align: center !important;
}

.margin-top-10{
  margin-top: 10px;
}

.margin-top-20{
  margin-top: 20px;
}

.cursor-pointer{
  cursor: pointer !important;
}