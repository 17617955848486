.spin-left-container, .finish-left-container{
    position: relative;
}

.spin-right-container, .finish-right-container{
    position: relative;
    padding: 80px 5% 50px 5% !important;
    border-left: 5px #000 dashed !important;
    border-image: url("../../assets/dottedLine.png") 300 stretch;
}

.spinning-image, .finish-image, .weaving-image{
    width: 100% !important;
}

.spinning-image-desc, .finish-image-desc{
    font-size: 60px !important;
    font-weight: bold !important;
    color: #603813 !important;
    position: absolute;
    top: 80px !important;
    right: 5% !important;
}

.weaving-image-desc{
    font-size: 60px !important;
    font-weight: bold !important;
    color: #603813 !important;
    position: absolute;
    top: 80px !important;
    left: 5% !important;
}

.spinning-desc, .finish-desc{
    font-family: 'Substance' !important;
    font-weight: 300 !important;
    font-size: 30px !important;
    /* font: normal normal 100 30px/37px 'Substance-thin' !important; */
    letter-spacing: 0px !important;
    color: #000000 !important;
    width: 409px;
}

.weaving-desc{
    font-family: 'Substance' !important;
    font-weight: 300 !important;
    font-size: 30px !important;
    /* font: normal normal 100 30px/37px 'Substance-thin' !important; */
    letter-spacing: 0px !important;
    color: #000000 !important;
    width: 480px;
    right: 5% !important;
    position: absolute;
    text-align: right;
}
.weaving-desc:after {
    /* position: absolute; */
    content: "";
    bottom: -40px;
    height: 40px;
    width: 1px;
}

.spinning-image-icon, .finish-image-icon{
    position: absolute !important;
    bottom: 50px !important;
}

.weaving-image-icon{
    position: absolute !important;
    bottom: 50px !important;
    right: 5% !important;
}

.weaving-right-container{
    position: relative;
    border-left: 5px #000 dashed !important;
    border-image: url("../../assets/dottedLine.png") 300 stretch;
}

.weaving-left-container{
    position: relative;
    padding: 80px 5% 50px 5% !important;
}

@media only screen and (max-width: 767px) {
    .spin-right-container, .finish-right-container{
        position: relative;
        padding: 30px 5% 30px 5% !important;
        border-left: 2px #000 dashed !important;
        border-image: url("../../assets/dottedLine.png") 300 stretch;
    }

    .spinning-image-desc, .finish-image-desc{
        font-size: 20px !important;
        top: 10px !important;
    }
    
    .weaving-image-desc{
        font-size: 20px !important;
        top: 10px !important;
    }

    .spinning-desc, .finish-desc{
        font-size: 13px !important;
        width: 120px;
    }
    
    .weaving-desc{
        font-size: 13px !important;
        width: 150px;
        right: 5% !important;
    }

    .spinning-image{
        height: 187px !important;
    } 
    .finish-image{
        height: 184px !important;
    }
    .weaving-image{
        height: 194px !important;
    }

    .spinning-image-icon, .finish-image-icon{
        bottom: 20px !important;
    }

    .spinning-image-icon{
        width: 76px !important;
        height: 60px !important;
    }

    .finish-image-icon{
        width: 74px !important;
        height: 51px !important;
    }
    
    .weaving-image-icon{
        bottom: 20px !important;
        width: 77px !important;
        height: 60px !important;
    }
    
    .weaving-right-container{
        border-left: 2px #000 dashed !important;
        border-image: url("../../assets/dottedLine.png") 350 stretch;
    }
    
    .weaving-left-container{
        padding: 30px 5% 30px 5% !important;
    }
}