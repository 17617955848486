.chain-container{
    padding: 100px 20% 0px 20% !important;
}

.chain-header{
    font-size: 70px !important;
    font-weight: bolder !important;
    color: #000000;
}

.chain-description{
    padding: 50px !important;
    font: normal normal 100 28px/36px 'Substance-thin' !important;
    letter-spacing: 0px !important;
    color: #000000 !important;
    opacity: 1 !important;
    text-align: left !important;
}

.chain-left-container{
    padding: 40px 70px 30px 5% !important;
    position: relative !important;
}
.chain-right-container{
    border-left: 5px #000 dashed !important;
    border-image: url("../../assets/dottedLine.png") 400 stretch;
    padding: 0px 5% 30px 5% !important;
}

.chain-image{
    width: 132px;
    height: 217px;
    position: absolute;
    right: 70px;
    top: 50%;
    transform: translate(0,-50%);
}

.chain-image-icon{
    width: 181px;
    height: 139px;
}
.chain-desc{
    width: 478px;
    height: 96px;
    font-size: 36px !important;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: bold !important;
}

.chain-date{
    font-weight: bold !important;
    font-size: 16px !important;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 20px !important;   
}

.chain-month-year{
    font-weight: bold !important;
    font-size: 52px !important;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    margin-bottom: 10px !important;
}

.chain-farm-certificate-container{
    position: relative !important;
}
.chain-farm-image{
    width: 100% !important;
}
.chain-certificate-image{
    position: absolute !important;
    right: 50% !important;
    bottom: 70px !important;
}

@media only screen and (max-width: 767px) {
    .chain-container{
        padding: 50px 5% 0px 5% !important;
    }
    
    .chain-header{
        font-size: 30px !important;
    }
    
    .chain-description{
        padding: 30px !important;
        font-size: 13px !important;
        font: normal normal 100 13px 'Substance-thin' !important;
    }
    
    .chain-left-container{
        padding: 30px 70px 30px 5% !important;
        position: relative !important;
    }
    .chain-right-container{
        border-left: 2px #000 dashed !important;
        border-image: url("../../assets/dottedLine.png") 350 stretch;
        padding: 30px 5% 30px 5% !important;
    }

    .chain-image{
        width: 61px;
        height: 101px;
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translate(0,-50%);
    }
    
    .chain-image-icon{
        width: 67px;
        height: 58px;
    }
    .chain-desc{
        width: 128px;
        height: 34px;
        font-size: 13px !important;
    }

    .chain-date{
        font-size: 10px !important;
    }
    .chain-month-year{
        font-size: 13px !important;
    }
    .chain-certificate-image{
        bottom: 30px !important;
        height: 123px !important;
        width: 87px !important;
    }
}