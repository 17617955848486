.cutsew-container{
    padding: 100px 20% 0px 20% !important;
}

.cutsew-header{
    font-size: 70px !important;
    font-weight: bolder !important;
    color: #000000;
}

.cutsew-description{
    padding: 50px !important;
    font-family: 'Substance' !important;
    font-weight: 300 !important;
    font-size: 38px !important;
    /* font: normal normal 100 38px/46px 'Substance-thin' !important; */
    letter-spacing: 0px !important;
    color: #000000 !important;
    opacity: 1 !important;
    text-align: left !important;
}

.cutsew-left-container{
    padding: 0px 70px 30px 5% !important;
    position: relative !important;
}
.cutsew-right-container{
    border-left: 5px #000 dashed !important;
    border-image: url("../../assets/dottedLine.png") 400 stretch;
    padding: 0px 5% 50px 7% !important;
}

.cutsew-image{
    width: 288px !important;
    height: 185px !important;
    float: right;
}
.cutsew-image-icon{
    margin-top: 20px !important;
    height: 100px !important;
}

.cut-image{
    width: 100% !important;
}

@media only screen and (max-width: 767px) {
    .cutsew-container{
        padding: 30px 5% 0px 5% !important;
    }
    
    .cutsew-header{
        font-size: 30px !important;
        font-weight: bolder !important;
        color: #000000;
    }
    
    .cutsew-description{
        padding: 30px 30px 20px 30px !important;
        font-size: 13px !important;
    }
    
    .cutsew-left-container{
        padding: 0px 30px 10px 5% !important;
        position: relative !important;
    }
    .cutsew-right-container{
        border-left: 2px #000 dashed !important;
        border-image: url("../../assets/dottedLine.png") 350 stretch;
        padding: 0px 5% 10px 7% !important;
    }

    .cutsew-image{
        height: 57px !important;
        width: 96px !important;
    }
    .cutsew-image-icon{
        margin-top: 5px !important;
        height: 57px !important;
        width: 88px !important;
    }
}