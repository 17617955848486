.weltrak-container{
    padding: 100px 20% !important;
    background: #E7E7E7 0% 0% no-repeat padding-box !important;
}

.weltrak-header{
    font-size: 80px !important;
    font-weight: bolder !important;
    color: #00B4E9;
}

.weltrak-left-container{
    margin-top: 80px !important;
    /* margin-bottom: 50px !important; */
}
.weltrak-right-container{
    margin-top: 50px !important;
    /* margin-bottom: 50px !important; */
    font-family: 'Substance' !important;
    font-weight: 300 !important;
    font-size: 33px !important;
    /* font: normal normal 100 33px/41px 'Substance-thin' !important; */
    letter-spacing: 0px !important;
    color: #000000 !important;
    opacity: 1 !important;
}
.weltrak-logo{
    width: 300px !important;
    height: 200px !important;
}
.weltrak-uid-no{
    font-size: 90px !important;
    font-weight: bolder !important;
    color: #603813;
}

@media only screen and (max-width: 767px) {
    .weltrak-container{
        padding: 30px 5% !important;
    }
    .weltrak-header{
        font-size: 30px !important;
    }
    .weltrak-logo{
        width: 120px !important;
        height: 80px !important;
    }
    .weltrak-uid-no{
        font-size: 30px !important;
    }
    .weltrak-left-container{
        margin-top: 30px !important;
    }
    .weltrak-right-container{
        margin-top: 20px !important;
        font-size: 13px !important;
    }
}