.home-container{
    background-color: #000000;
    background: transparent url("../../assets/backgroundImage.png") no-repeat !important;
    padding: 0px 20% !important;
    color: #fff;
    opacity: 1;
    background-size: cover !important;
    /* height: auto !important; */
    width: 100% !important;
}


.tag-line{
    margin: 500px 0px 130px 0px !important;
    font-weight: bolder !important;
    font-size: 80px !important;
    letter-spacing: 0px;
    /* height: 100px; */
}

@media only screen and (min-height: 1080px) {
    .home-container{
        height: 100vh !important;
    }
    .tag-line{
        margin: 67vh 0px 10vh 0px !important;
    }
}

.home-footer-container{
    display: flex;
    flex-direction: row;
    margin-bottom: 40px !important;
}

.app-icon-home{
    width: 92px;
    height: 92px;
    margin-right: 20px !important;
}

.foot-tag-line{
    flex: 1 !important;
    align-self: center;
    font-weight: bold !important;
    font-size: 22px !important;
}

@media only screen and (max-width: 767px) {
    .home-container{
        padding: 0px !important;
    }

    .tag-line{
        margin: 105px 0px 38px 0px !important;
        font-weight: bolder !important;
        font-size: 30px !important;
    }

    .home-footer-container{
        margin-bottom: 20px !important;
        padding-left: 0px;
    }

    .app-icon-home{
        width: 30px;
        height: 30px;
        margin-right: 5px !important;
    }

    .foot-tag-line{
        font-size: 13px !important;
        line-height: 30px;
        align-self: auto;
    }
}