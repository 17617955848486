.header-container{
    padding: 0px 20% !important;
    color: #fff !important;
    margin-top: 50px !important;
    box-shadow: none !important;

    /* animation */
    -webkit-transition: all 0.5s ease !important;
    -moz-transition: all 0.5s ease !important;
    transition: all 0.5s ease !important;
}

.small-header-container{
    margin-top: 0px !important;
    color: #603813 !important;
    padding: 20px 20% !important;
    background-color: #fff !important;
    
}

.toolbar-header{
    display: flex;
    flex-direction: row;
}
.menu-icon{
    display: none !important;
    width: 16px !important;
    align-self: center;
}
.app-logo-header{
    /* width: 149px !important; */
    /* height: 100px !important; */
}
.empty-span{
    flex: 1;
}

.logo-action-outer{
    display: block !important;
}
.logo-action-inner{
    text-align: center !important;
    display: none !important;
}

.app-icon-header{
    width: 142px !important;
    height: 50px !important;
    align-self: center;
    margin-left: 20px !important;
}

.menu-item{
    align-self: center;
    font: normal normal bold 13px/29px Substance !important;
    /* color: #fff !important; */
    padding: 0px 20px !important;
}

@media only screen and (max-width: 767px) {
    .app-logo-header{
        width: 58px !important;
        height: 36px !important;
        text-align: center !important;
    }

    .logo-action-outer{
        display: none !important;
    }
    .logo-action-inner{
        display: block !important;
    }

    .header-container{
        padding: 0px 5% !important;
        margin-top: 20px !important;
    }

    .small-header-container{
        margin-top: 0px !important;
        padding: 5px 5% !important;
        background-color: #fff !important;
    }

    .menu-item{
        display: none !important;
        font: normal normal bold 10px Substance !important;
        padding: 0px 10px !important;
    }

    .app-icon-header{
        width: 40px !important;
        height: 20px !important;
        margin-left: 0px !important;
    }

    .menu-icon{
        display: block !important;
        width: 40px !important;
    }
}