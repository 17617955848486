.welspun-map-image{
    margin-top: 70px !important;
}

.pack-ship-right-container{
    border-left: 5px #000 dashed !important;
    border-image: url("../../assets/dottedLine.png") 400 stretch;
    padding: 0px 5% 50px 5% !important;
}

.pack-ship-image-icon{
    margin-top: 20px !important;
    height: 100px !important;
}

.pack-ship-desc{
    font-size: 37px;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    width: 317px;
    margin-top: 10px !important;
}

.pack-ship-date{
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 50px !important;
}
.pack-ship-month-year{
    font-size: 72px;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}
.retail-left-container{
    text-align: right;
    padding: 0px 5% 50px 5% !important;
    position: relative;
}
.retail-right-container{
    border-left: 5px #000 dashed !important;
    border-image: url("../../assets/dottedLine.png") 400 stretch;
}
.retail-image-icon{
    
}
.retail-desc-container{
    display: flex;
    flex-direction: row;
    margin-bottom: 50px !important;
}
.retail-empty-div{
    flex: 1;
}
.retail-desc{
    font-size: 37px;
    width: 366px;
    font-weight: bold;
    text-align: right;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 10px !important;
}

.retail-black-right-container{
    border-left: 5px #000 dashed !important;
    border-image: url("../../assets/dottedLine.png") 400 stretch;
    padding: 0px 5% 0px 5% !important;
    position: relative;
}

.retail-black-date{
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-top: 50px !important;
}
.retail-black-month-year{
    font-size: 72px;
    font-weight: bold;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.retail-black-icon{
    width: 80px !important;
    height: 80px !important;
    position: absolute;
    left: -43px;
    bottom: 0px;
}



.walmart-background-image{
    margin-top: 80px !important;
    width: 100% !important;
}
.question-right-container{
    padding: 30px 5% 50px 5% !important;
    position: relative !important;
}
.walmart-question{
    font-size: 37px !important;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: bold !important;
   
}

.welspun-final-icon{
    position: absolute !important;
    height: 50px !important;
    width: 142px !important;
    bottom: 25px !important;
    top: 15px !important;
    right: 50px !important;
}

@media only screen and (max-width: 767px) {
    .welspun-map-image{
        /* width: 100% !important; */
        height: 200px !important;
        margin-top: 50px !important;
    }

    .pack-ship-image-icon{
        margin-top: 5px !important;
        height: 60px !important;
        width: 103px !important;
    }
    
    .pack-ship-desc{
        font-size: 13px !important;
        width: 111px;
        margin-top: 10px !important;
    }
    .pack-ship-right-container{
        border-left: 2px #000 dashed !important;
        border-image: url("../../assets/dottedLine.png") 350 stretch;
        padding: 0px 5% 30px 5% !important;
    }
    .pack-ship-date{
        font-size: 10px;
        margin-top: 10px !important;
    }
    .pack-ship-month-year{
        font-size: 13px;
    }


    .retail-image-icon{
        height: 56px !important;
        width: 46px !important;
    }
    .retail-desc{
        font-size: 13px;
        width: 128px;
        margin-top: 5px !important;
    }
    .retail-black-right-container{
        border-left: 2px #000 dashed !important;
        border-image: url("../../assets/dottedLine.png") 350 stretch;
    }
    
    .retail-black-date{
        font-size: 10px;
        margin-top: 10px !important;
    }
    .retail-black-month-year{
        font-size: 13px;
    }
    
    .retail-black-icon{
        width: 39px !important;
        height: 39px !important;
        position: absolute;
        left: -20px;
        bottom: 0px;
    }
    .retail-right-container{
        border-left: 2px #000 dashed !important;
        border-image: url("../../assets/dottedLine.png") 350 stretch;
    }

    .walmart-background-image{
        margin-top: 33px !important;
    }
    .question-right-container{
        padding: 10px 5% 50px 5% !important;
        position: relative !important;
    }
    .welspun-final-icon{
        height: 30px !important;
        width: 80px !important;
        top: 15px !important;
        left: 20px !important;
    }
}