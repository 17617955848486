.authenticity-container{
    padding: 100px 20% 0px 20% !important;
}

.authenticity-header{
    font-size: 70px !important;
    font-weight: bolder !important;
    color: #000000;   
}

.authenticity-description{
    padding: 50px !important;
    font-family: 'Substance' !important;
    font-weight: 300 !important;
    font-size: 28px !important;
    /* font: normal normal 100 28px/36px 'Substance-thin' !important; */
    letter-spacing: 0px !important;
    color: #000000 !important;
    opacity: 1 !important;
    text-align: left !important;
}

.authenticity-left-container{
    padding: 40px 70px 30px 5% !important;
    position: relative !important;
}
.authenticity-right-container{
    border-left: 5px #000 dashed !important;
    border-image: url("../../assets/dottedLine.png") 400 stretch;
    padding: 40px 5% 30px 5% !important;
}

.authenticity-image{
    width: 187px !important;
    height: 186px !important;
    position: absolute;
    right: 70px;
    top: 50%;
    transform: translate(0,-50%);
}
.map-image-container{
    /* margin-top: 50px !important; */
}
.authenticity-lot-no{
    width: 478px;
    height: 96px;
    font-size: 36px !important;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: bold !important;
}

.authenticity-farm-image{
    height: 100% !important;
    width: 100% !important;
}

.flag-image{
    width: 115px !important;
    height: 77px !important;
    position: absolute;
}
.map-image{
    width: 100% !important;
    height: 620px !important;
    margin-top: 70px !important;
    margin-bottom: 20px !important;
}

.flag-map-image-container{
    border-left: 5px #000 dashed !important;
    border-image: url("../../assets/dottedLine.png") 150 stretch;
    position: relative;
}

@media only screen and (max-width: 767px) {
    .authenticity-container{
        padding: 30px 5% 0px 5% !important;
    }
    .authenticity-header{
        font-size: 30px !important;
    }
    .authenticity-description{
        padding: 30px !important;
        font-size: 13px !important;
    }
    .authenticity-left-container{
        padding: 30px 70px 30px 5% !important;
    }
    .authenticity-right-container{
        padding: 30px 5% 30px 5% !important;
        border-left: 2px #000 dashed !important;
        border-image: url("../../assets/dottedLine.png") 400 stretch;
    
    }
    .authenticity-image{
        width: 84px !important;
        height: 84px !important;
        right: 30px;
    }
    .authenticity-image-icon{
        height: 56px !important;
        width: 82x !important;
    }

    .authenticity-farm-image{
        height: 190px !important;
    }
    .flag-image{
        width: 27px !important;
        height: 18px !important;
        position: absolute;
    }
    .map-image{
        height: 166px !important;
        margin-top: 10px !important;
        margin-bottom: 20px !important;
    }
    .flag-map-image-container{
        border-left: 2px #000 dashed !important;
        border-image: url("../../assets/dottedLine.png") 350 stretch;
    }
}