.walmart-container{
    
}
.walmart-image{
    width: 100% !important;
    /* height: 500px !important; */
}

.walmart-left-container{
    position: relative !important;
    /* border-width: 5px !important; */
    /* padding-bottom: 70px !important; */
}

.blockchain-image{
    height: 150px !important;
    width: 150px !important;
    position: absolute;
    bottom: 0px;
    right: 90px;

}

.walmart-right-container{
    border-left: 5px #000 dashed !important;
    border-image: url("../../assets/dottedLine.png") 30 stretch;
    padding: 50px 5% 0px 5% !important;
}

.margin-bottom-70{
    margin-bottom: 70px !important;
}

.walmart-icon{
    /* height: 100px; */
    width: 200px !important;
}

.walmart-item-header{
    font-size: 28px !important;
    font-weight: 600 !important;
    color: #4D4D4D !important;
    font-family: 'Kenac-semibold' !important;
}
.walmart-item-description{
    font-size: 23px !important;
    margin: 2px 0px !important;
    color: #4D4D4D !important;
    font-family: 'Substance' !important;
}

.item-container{
    margin-top: 20px !important;
}

@media only screen and (max-width: 767px) {
    .walmart-right-container{
        padding: 5px 5% 0px 5% !important;
        border-left: 2px #000 dashed !important;
    }
    .item-container{
        margin-top: 10px !important;
    }
    .walmart-item-header{
        font-size: 13px !important;    
    }
    .walmart-item-description{
        font-size: 10px !important;    
    }
    .walmart-left-container .walmart-image{
        height: 100% !important;
        object-fit: fill !important;
    }
    .last-item{
        margin-bottom: 10px !important;
    }
}