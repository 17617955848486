.manufacturing-container{
    padding: 100px 20% 0px 20% !important;
}

.manufacturing-header{
    font-size: 70px !important;
    font-weight: bolder !important;
    color: #000000;
}

.manufacturing-left-container{
    padding: 40px 50px 30px 0% !important;
    position: relative !important;
}
.manufacturing-right-container{
    border-left: 5px #000 dashed !important;
    border-image: url("../../assets/dottedLine.png") 400 stretch;
    padding: 30px 5% 50px 5% !important;
    margin-top: 150px !important;
}

.manufacturing-image{
    width: 100%;
    height: 100%;
}

.manufacturing-image-icon{
    width: 347px;
    height: 155px;
}
.manufacturing-desc{
    width: 478px;
    height: 96px;
    font-size: 36px !important;
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    font-weight: bold !important;
}

@media only screen and (max-width: 767px) {
    .manufacturing-container{
        padding: 50px 5% 0px 5% !important;
    }
    
    .manufacturing-header{
        font-size: 30px !important;
    }
    
    .manufacturing-left-container{
        padding: 30px 30px 30px 0% !important;
        position: relative !important;
    }
    .manufacturing-right-container{
        border-left: 2px #000 dashed !important;
        border-image: url("../../assets/dottedLine.png") 350 stretch;
        padding: 20px 5% 30px 5% !important;
        margin-top: 30px !important;
    }
    
    .manufacturing-image{
        width: 161px;
        height: 142px;
        float: right;
    }
    
    .manufacturing-image-icon{
        width: 125px;
        height: 64px;
    }
    .manufacturing-desc{
        width: 128px;
        height: 50px;
        font-size: 13px !important;
    }   
}